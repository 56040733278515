<template>
  <div>
    <div @click="generateReport()" class="btn">Obtenir les résultats sous forme de PDF</div>
    <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="Résultats du quiz"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        :pdf-margin="10"
        pdf-orientation="portrait"
        pdf-content-width="800px"

        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <template v-slot:pdf-content>
          <div style="margin: 0px 0px" id="pdf-results">
            <header>
              <img src="@/assets/pdf/header-pdf.jpg" alt="">
            </header>
            <main>
              <section class="titre">
                <h1>Mes résultats</h1>
              </section>
              <section class="content">
                <div class="pdf-jobsList" data-simplebar>
                  <ul>
                    <li class="job" v-for="(result, index) in results" :key="`result-${index}`"
                    :style="{color:percentageColor(result.percentage)}"
                    >
                      <div class="count">{{ index+1 }}. </div>
                      <span>{{ jobs[result.jobIndex].metier }}</span>
                      <span class="percentage">{{ result.percentage }}%</span>
                    </li>
                  </ul>
              </div>
              </section>
            </main>
            <footer>
              <img src="@/assets/pdf/footer-pdf.jpg" alt="">
            </footer>
          </div>
        </template>
    </vue3-html2pdf>
  </div>
</template>

<script>
import Vue3Html2pdf from 'vue3-html2pdf'

export default {
  name: 'printPdfButton',
  components: {
    Vue3Html2pdf
  },

  methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    percentageColor ($percentage) {
      let color = 'black'
      if ($percentage >= 90) {
        color = 'green'
      } else if ($percentage >= 80 && $percentage < 90) {
        color = 'YellowGreen'
      } else if ($percentage >= 70 && $percentage < 80) {
        color = 'orange'
      } else if ($percentage >= 0 && $percentage < 70) {
        color = 'Tomato'
      }
      return color
    }
  },

  computed: {
    jobs () {
      return this.$store.state.jobs
    },
    userResponses () {
      return this.$store.state.userResponses
    },
    results () {
      return this.$store.state.results
    }
  }
}
// https://codesandbox.io/s/gautamkabiraj96-vuejs-html-to-pdf-generate-example-6bgmf?file=/src/App.vue:32-93
</script>

<style lang="scss">

  .pdf-results-link {
    margin-top: 1em;
    text-decoration: underline;
    cursor: pointer;
  }

  #pdf-results {
    background-color: #fff;
    position: relative;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    img {
      width: 100%;
    }

    main {
      display: flex;
      padding: 0 45px;
      min-height: 700px;
      // overflow: hidden;
      align-content: flex-start;
      justify-content: flex-start;

      .titre {
        width: 30%;
        h1 {
          font-size: 30px;
          color: #000;
          text-align: left;
          padding-top: 0;
          margin-top: 0;
        }
      }

      .content {
        width: 70%;

        .pdf-jobsList {
          text-align: right;

          ol,
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            padding-right: 0;

            li.job {
              cursor: pointer;
              font-size: 14px;
              margin-left: 0px;
              line-height: 1.2;
              margin-bottom: 8px;
              position: relative;

              .percentage {
                &:before {
                  content: ' - ';
                }
              }

              .count{
                position:relative;
                display: inline-block;
                padding-right: 5px;
              }
            }
          }
        }
      }
    }

    footer {
      margin-top: 35px;
    }
  }
</style>
